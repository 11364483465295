body {
    font-family: var(--font-regular);
    font-size: 1.6rem;
    line-height: 1.56;
    color: var(--default-text-color);

    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    width: 100%;
    min-width: 32rem;
    min-height: calc(100vh - 8.6rem);
    margin: 8.6rem auto 0;
    background-color: var(--color-off-white) !important; // Important is needed to apply color in pagebuilder

    position: relative;
    scroll-behavior: smooth;

    &[type="with-image"] {
        margin-top: 0;

        &::after {
            display: none;
        }
    }

    &.-no-overflow {
        overflow-y: hidden;
    }
}

@media (--screen-medium-large) {
    body {
        font-size: 1.8rem;
        min-height: calc(100vh - 10.2rem);
        margin: 10.2rem auto 0;
    }
}

@media only screen and (min-width: 800px) {
    body {
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            background-image: url("includes/bg-image.png");
            background-size: contain;
            background-repeat: no-repeat;
            width: 14rem;
            height: 38rem;
        }
    }
}

@media only screen and (min-width: 1150px) {
    body {
        &::after {
            width: 28rem;
            height: 76.3rem;
        }
    }
}
