.downloads-wrapper {
    max-width: 62rem;
    margin: 3.5rem auto 6rem;
}

.downloads {
    @include no-list;

    h2 + & {
        margin-top: 2.5rem;
    }

    & > :nth-child(n):not(:last-child) {
        border-bottom: .1rem solid var(--color-borders);
    }
}

@media (--screen-medium-large) {
    .downloads {
        h2 + & {
            margin-top: 4.5rem;
        }
    }
}
