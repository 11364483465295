.partners {
    @include no-list;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.partners__item {
    display: block;
    margin: 0 1rem 2.3rem;

    img {
        display: block;
        width: 100%;
        height: 100%;
        max-height: 6rem;
        object-fit: contain;
    }
}

@media (--screen-medium-large) {
    .partners__item {
        margin-bottom: 0;
    }
}
