.form__upload {
    .form__label {
        display: block;
    }
}

.form__upload__file {
    display: block;
    width: 100%;
    height: 5rem;
    padding: 1.5rem 0 1.5rem 21rem;
    box-sizing: border-box;
    position: relative;
    outline: none;

    font-size: 1.6rem;

    @media (--screen-medium-large) {
        font-size: 1.7rem;
    }

    &:valid {
        color: var(--color-blue);
    }
}

.form__upload__file[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
    width: 0;
}

.form__upload__button {
    position: absolute;
}
