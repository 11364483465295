.button {
    display: inline-block;
    margin: 0;
    padding: 1.2rem 2.8rem;
    background-color: var(--color-crimson-red);
    border-radius: 10rem;
    color: var(--color-off-white);
    font-family: var(--font-bold);
    font-size: 1.8rem;
    text-decoration: none;
    line-height: 1.8;
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color .3s ease;

    &:hover, &:focus {
        background-color: var(--color-crimson-red-hover);
    }

    &.-purple {
        background-color: var(--color-pink-lavender);

        &:hover, &:focus {
            background-color: var(--color-pink-lavender-hover);
        }
    }

    &.-green {
        background-color: var(--color-bright-green);
        color: var(--color-blue-green);

        &:hover, &:focus {
            background-color: var(--color-bright-green-hover);
        }
    }

    &.-yellow {
        background-color: var(--color-bright-yellow);
        color: var(--color-blue-green);

        &:hover, &:focus {
            background-color: var(--color-bright-yellow-hover);
        }
    }
}
