.dropdown {
    @include no-list;
    position: relative;
    width: 6rem;
    display: none;

    .page-header.-open & {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-bottom: 2.8rem;
    }
}

.dropdown__trigger {
    span {
        display: none;
    }
}

.dropdown__panel {
    @include no-list;
    display: flex;
    align-items: center;
}

.dropdown__link {
    display: block;
    margin-right: 1.7rem;

    font-family: var(--font-bold);
    font-size: 1.6rem;
    line-height: 1.2;
    color: var(--color-blue-green);
    opacity: .6;
    text-decoration: none;
    cursor: pointer;
    transition: all .2s ease;

    & :last-of-type {
        margin-right: 0;
    }

    &:hover, &:focus, &.-active {
        opacity: 1;
    }
}

@media (--screen-extra-large) {
    .dropdown {
        display: block;
        margin-left: 2.2rem;
    }

    .dropdown__trigger {
        padding: .5rem 1rem;
        cursor: pointer;

        span {
            display: block;
            position: relative;
            font-family: var(--font-bold);
            font-size: 1.6rem;
            line-height: 1.2;
            color: var(--color-blue-green);
            transition: all .3s ease;

            &::after {
                content: '';
                position: absolute;
                top: .7rem;
                right: 0;
                background-image: url("../icons/arrow-down.svg");
                background-repeat: no-repeat;
                background-size: 1rem .55rem;
                width: 1rem;
                height: .55rem;
                transition: all .3s ease;

                @supports (mask-repeat: no-repeat) {
                    background: var(--color-blue-green);
                    mask-image: url("../icons/arrow-down.svg");
                    mask-repeat: no-repeat;
                    mask-size: 1rem .55rem;
                }
            }

            .dropdown.-open & {
                &::after {
                    transform: rotate(180deg);
                }
            }

            .page-header.-off-white & {
                color: var(--color-off-white);

                &::after {
                    @supports (mask-repeat: no-repeat) {
                        background: var(--color-off-white);
                        mask-image: url("../icons/arrow-down.svg");
                        mask-repeat: no-repeat;
                        mask-size: 1rem .55rem;
                    }
                }

                .dropdown.-open & {
                    color: var(--color-off-white);

                    &::after {
                        @supports (mask-repeat: no-repeat) {
                            background: var(--color-off-white);
                        }
                    }
                }
            }
        }
    }

    .dropdown__panel {
        display: block;
        position: absolute;
        left: 0;
        margin-top: .4rem;
        width: 100%;
        background-color: var(--color-off-white);
        opacity: 0;
        max-height: 0;
        transition: opacity .3s ease, max-height .3s ease;

        &.-open {
            opacity: 1;
            max-height: none;
        }
    }

    .dropdown__link {
        margin: 0;
        padding: .8rem 1rem;
        opacity: 1;

        &.-active {
            display: none;
        }

        &:hover, &:focus {
            background-color: var(--color-blue-green);
            color: var(--color-off-white);
        }
    }
}
