.download {
    display: flex;
    justify-content: space-between;
    padding: 1.6rem 0;
    margin: 0;
}

.download__info {
    width: calc(100% - 5rem);
}

.download__title {
    margin: 0 0 .6rem;

    a {
        color: var(--color-blue-green);
        text-decoration: none;
        transition: color .3s ease;
    }

    &:hover, &:focus {
        a {
            color: var(--color-crimson-red);
        }
    }
}

.download__meta {
    display: flex;
    align-items: center;

    span {
        font-size: 1.2rem;
        line-height: 1.2;
        white-space: nowrap;
    }
}

.download__meta__size {
    position: relative;

    &::before {
        content: '\2027';
        display: inline-block;
        margin: 0 .8rem;
    }
}

.download__action {
    display: block;
    padding: 0;
    width: 3.2rem;
    height: 3.2rem;
    box-sizing: border-box;
    text-align: center;
    background-image: url("../icons/download-icon.svg");
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    background-position: center center;
    border-radius: 49%;
}

@media (--screen-medium-large) {
    .download {
        padding: 2rem 0;
    }

    .download__info {
        width: calc(100% - 6rem);
    }

    .download__action {
        width: 4.8rem;
        height: 4.8rem;
        background-size: 1.4rem 1.4rem;
    }
}
