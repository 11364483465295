.jumbotron__vimeo {
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    height: 100vh;
    position: relative;

    iframe {
        max-width: unset;
        border: none;
    }

    &.-playing .jumbotron__vimeo__box {
        background-image: none !important;
    }
}

.jumbotron__vimeo__box {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    min-height: 20rem;
}

.jumbotron__vimeo__player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

div.jumbotron__vimeo__player {
    width: 100%;
    height: 100%;

    iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
