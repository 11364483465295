// builds on call-to-action.scss
[data-block-type="call-to-action-image"] {
    background-color: var(--color-blue-green);
}

.call-to-action.-image {
    overflow: hidden;
    text-align: left;

    & > * {
        max-width: none;
        margin: 0;
    }
}

.call-to-action__image {
    margin-bottom: 4.2rem;
    text-align: right;

    img {
        width: 100%;
        margin: 0 -3.8rem 4.2rem 0;
    }
}

.call-to-action__content {
    text-align: center;

    & > * {
        max-width: 50.4rem;
        margin: 0 auto;
    }
}

@media (--screen-medium-large) {
    .call-to-action.-image {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        padding: 9.2rem 0 10.2rem;
    }

    .call-to-action__image {
        text-align: right;
        width: 50%;
        margin-right: -4.5rem;

        img {
            max-width: 95%;
            margin: 0 -4.5rem 0 0;
        }
    }

    .call-to-action__content {
        width: 50%;
        text-align: left;
        padding: 0 2rem;
    }
}
