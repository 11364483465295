.childpages {
    max-width: calc(128rem + 4rem); /* max-width + 4rem margin-right elements large */
    margin: 4rem auto 3rem;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    & > :nth-child(n) {
        width: 100%;
    }
}

@media (--screen-medium-large) {
    .childpages {
        margin: 7rem auto 5rem;

        & > :nth-child(n) {
            width: calc(50% - 2rem);
        }

        & > :nth-child(odd) {
            margin-right: 2rem;
        }
    }
}

@media (--screen-large) {
    .childpages {
        margin: 16.4rem -4rem 5rem auto;

        & > :nth-child(n) {
            width: calc(100% / 3 - 4rem);
            margin: 0 4rem 4rem 0;
        }
    }
}
