form, .form {
    margin-bottom: 4rem;

    fieldset {
        border: 0;
        margin: 0 0 1rem;
        padding: 0;
        max-width: 59rem;
    }

    .special-password {
        display: none;
    }
}

.form__title {
    margin: 0 0 2.2rem;
}

.form__list {
    @include no-list;
}

@media (--screen-medium-large) {
    form, .form {
        margin-bottom: 9rem;
    }
}
