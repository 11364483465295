[data-block-type="call-to-action"] {
    background-color: var(--color-blue-green);
}

.call-to-action {
    width: 100%;
    padding: 5.5rem 2rem 6.1rem;
    box-sizing: border-box;
    background-color: var(--color-blue-green);
    text-align: center;

    & > * {
        max-width: 62rem;
        margin: 0 auto;
    }
}

.call-to-action__title {
    font-family: var(--font-bold);
    font-size: 3.2rem;
    color: var(--color-off-white);
    margin-bottom: 1.8rem;
}

.call-to-action__text {
    color: var(--color-off-white);
    margin-bottom: 4.4rem;

    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }
}

.call-to-action__actions {
    margin-top: 2.4rem;
}

@media (--screen-medium-large) {
    .call-to-action {
        padding: 6.2rem 0;
    }

    .call-to-action__title {
        font-size: 6.4rem;
        margin-bottom: 2.6rem;
    }
}
