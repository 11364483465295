.half-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 4rem auto;

    &.-center {
        flex: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.half-text__image {
    width: 100%;
    object-fit: cover;
    order: 0;
    display: flex;
    align-items: center;

    .image, .image img {
        object-fit: cover;
        width: 100%;
        max-height: 100%;
        height: auto;
        border-radius: 0 40rem 40rem 0;
        margin-right: 2rem;
    }
}

.half-text__content {
    order: 1;
    box-sizing: border-box;
    padding: 2rem 1.6rem;

    .half-text:nth-of-type(even) &, .half-text.-reverse & {
        margin-left: auto;
    }

    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }
}

.half-text__title {
    margin-bottom: 1.2rem;
}

.half-text__text {
    margin-bottom: 3.3rem;
}

@media (--screen-large) {
    .half-text {
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
    }

    .half-text__image {
        width: 50%;
        order: -1;

        .image, .image img {
            margin-right: 0;
        }

        .half-text:nth-of-type(even) &, .half-text.-reverse & {
            order: 1;

            .image, .image img {
                border-radius: 40rem 0 0 40rem;
            }
        }
    }

    .half-text__content {
        box-sizing: border-box;
        width: 50%;
        max-width: 58rem;
        padding: 0 3.2rem 0 7rem;

        .half-text:nth-of-type(even) &, .half-text.-reverse & {
            order: 0;
            max-width: 61rem;
            padding: 0 10rem 0 3.2rem;
        }
    }

    .half-text__title {
        margin-bottom: 3rem;
    }

    .half-text__text {
        margin-bottom: 4.2rem;
    }
}
