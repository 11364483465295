@import '~@inventis/cookieconsent/src/cookie-consent.scss';

:root {
    --cookie-consent-options-background-color: var(--color-off-white);
}

.cookie-consent__form {
    margin-block-end: 0;
}

.cookie-consent__title {
    text-align: start;
}

.cookie-consent {
    .form-label {
        font-family: var(--font-bold);
    }

    .form-row:not(:first-child) {
        margin-block-start: 1em;
    }
}
