[data-block-type="quote"] {
    text-align: center;
}

.quote {
    display: block;
    width: 100%;
    max-width: 62rem;
    margin: 0 auto 14rem;

    background-color: var(--color-pink-lavender);
    padding: 6rem;
    border-radius: 100rem;
    text-align: center;
    box-sizing: border-box;
    box-shadow: longshadow(#AA73E6, $bg, 100, 1);

    & > * {
        max-width: 53rem;
        margin: 0 auto;
    }

    [data-block-type="quote"].-pink & {
        background-color: var(--color-crimson-red);
        box-shadow: longshadow(#DC55A5, $bg, 100, 1);
    }
}

.quote__quote {
    margin: 0;
    font-family: var(--font-bold);
    color: var(--color-off-white);
    font-size: 2.6rem;
    line-height: 1.2;
    text-align: center;

    &::before {
        content: '\201C';
    }

    &::after {
        content: '\201D';
    }
}

.quote__author {
    margin: 1.6rem 0 0;
    color: var(--color-off-white);
    font-size: 1.6rem;
    text-align: center;
}

@media (--screen-medium-large) {
    .quote {
        margin: 4rem auto 16rem;
    }

    .quote__quote {
        font-size: 3.6rem;
    }

    .quote__author {
        margin: 3.3rem 0 0;
        font-size: 1.8rem;
    }
}
