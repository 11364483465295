/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
    --color-white:                  #ffffff;
    --color-black:                  #000000;
    --color-blue-green:             #2D4B64;
    --color-off-white:              #FAF5EB;
    --color-bright-green:           #6EE687;
    --color-bright-green-hover:     #87EA98;
    --color-bright-yellow:          #FFD21E;
    --color-bright-yellow-hover:    #FFD84D;
    --color-gallery-yellow:         rgba(241, 193, 0, .8);
    --color-pink-lavender:          #AA73E6;
    --color-pink-lavender-hover:    #B887EA;
    --color-crimson-red:            #DC55A5;
    --color-crimson-red-hover:      #E370B1;
    --color-borders:                #CCC2AB;
    --color-borders-50:             rgba(204, 194, 171, .5);

    --font-regular: 'BW-Gradual-Regular', sans-serif;
    --font-bold:    'BW-Gradual-Bold', sans-serif;
    --default-text-color: var(--color-blue-green);
}

@custom-media --screen-extra-large  (width >= 1050px);
@custom-media --screen-large        (width >= 850px);

@custom-media --screen-medium-large (width >= 630px);
@custom-media --screen-medium       (width >= 630px) and (width < 850px);

@custom-media --screen-small-large  (width >= 420px);
@custom-media --screen-small-medium (width >= 420px) and (width < 850px);
@custom-media --screen-small        (width >= 420px) and (width < 630px);

@custom-media --screen-mini-medium  (width < 850px);
@custom-media --screen-mini-small   (width < 630px);
@custom-media --screen-mini         (width < 420px);
