.childpage {
    display: block;
    margin-bottom: 2rem;

    font-family: var(--font-bold);
    font-size: 1.8rem;
    line-height: 1.4;
    color: var(--color-blue-green);
    text-decoration: none;
}

.childpage__image {
    position: relative;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    min-height: 24.2rem;
    object-fit: cover;
    transition: all .3s ease;

    .childpage:hover &,
    .childpage:focus & {
        top: -1rem;
        opacity: .9;
    }
}

.childpage__title {
    position: relative;
    display: block;
    margin: 1.1rem 0 0;

    width: 100%;
    padding-right: 2rem;
    box-sizing: border-box;
    transition: all .3s ease;

    &::after {
        content: '';
        position: absolute;
        right: 0;
        display: inline-block;
        background-image: url("../icons/arrow-icon.svg");
        background-position: right center;
        background-size: .66rem 1.2rem;
        background-repeat: no-repeat;
        width: .66rem;
        height: 1.2rem;

        @supports (mask-repeat: no-repeat) {
            background: var(--color-blue-green);
            mask-position: right center;
            mask-image: url("../icons/arrow-icon.svg");
            mask-size: .66rem 1.2rem;
            mask-repeat: no-repeat;
        }
    }


    .childpage:hover &,
    .childpage:focus & {
        color: var(--color-crimson-red);

        &::after {
            @supports (mask-repeat: no-repeat) {
                background: var(--color-crimson-red);;
            }
        }
    }
}
