%form__field-reset {
    transition: all .2s ease;
    box-sizing: border-box;

    -webkit-appearance: none !important; // HARD reset to remove shadow in input for IOS

    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 1rem 1.5rem;
    vertical-align: baseline;

    font-family: var(--font-regular);
    font-size: 1.5rem;
    line-height: 1.5;

    color: var(--color-blue-green);
    background: transparent;
    border: .1rem solid var(--color-borders);
    border-radius: .6rem;

    // Firefox outline style
    outline: none;

    &::placeholder {
        color: var(--color-blue-green);
    }

    @media (--screen-medium-large) {
        padding: 1.2rem 2.4rem;
        font-size: 1.6rem;
    }
}

// FF cursor pointer bug fix
input {
    cursor: pointer;
}

.form__field {
    @extend %form__field-reset;

    &:focus {
        outline: none;
        border-color: var(--color-green);
    }

    &.-textarea {
        resize: vertical;
    }

    .form__entry.-invalid & {
        border: .2rem solid var(--color-crimson-red);
        background-color: rgba(220, 85, 165, .2);

        // Firefox outline style & shadow
        outline: none;
        box-shadow: none;
    }

    &.-has-value,
    &[data-component='form/hasValue']:focus {
        & ~ .form__label {
            font-size: 1.2rem;
            transform: translateY(-.5rem);
        }
    }

    &.-select {
        background-image: url("includes/select.svg");
        background-position-y: center;
        background-position-x: calc(100% - 1.6rem);
        background-repeat: no-repeat;
    }
}

