@font-face {
    font-family: 'BW-Gradual-Regular';
    src:
        url('Bw-Gradual-Regular.otf') format('opentype'),
        url('Bw-Gradual-Regular.ttf') format('truetype'),
        url('Bw-Gradual-Regular.woff') format('woff'),
        url('Bw-Gradual-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'BW-Gradual-Bold';
    src:
        url('Bw-Gradual-Bold.otf') format('opentype'),
        url('Bw-Gradual-Bold.ttf') format('truetype'),
        url('Bw-Gradual-Bold.woff') format('woff'),
        url('Bw-Gradual-Bold.woff2') format('woff2');
}
