.page-head {
    position: relative;
    display: block;
    margin-bottom: 4rem;

    .container {
        max-width: 106rem;
    }
}

.page-head__image {
    position: relative;
    width: 100%;

    img {
        @include image;
        min-height: 40rem;
        max-height: 60rem;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2));
        background-size: cover;
    }
}

.page-head__content {
    max-width: 75rem;

    h1 {
        text-align: left;
        margin: 0;

        .page-head.-with-image & {
            color: var(--color-off-white);
        }
    }

    .page-head.-with-image & {
        position: absolute;
        bottom: 5.6rem;

        h1 {
            color: var(--color-off-white);
        }
    }
}

@media (--screen-medium-large) {
    .page-head {
        margin-bottom: 7.6rem;

        &.-with-image .page-head__content {
            bottom: 7.6rem;
        }
    }
}
