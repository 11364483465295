.jumbotron {
    position: relative;
    height: 100%;
    background-color: var(--color-blue-green);
    margin-bottom: 6.2rem;

    .container {
        max-width: 106rem;
    }
}

.jumbotron__image {
    position: relative;
    background-image: url("../../styleguide-images/image_2.jpg"); // fallback
    transition: background 2.5s ease-in-out;

    &.-okapi {
        background-image: url("../../styleguide-images/image_2.jpg");
    }

    &.-gorilla {
        background-image: url("../../styleguide-images/image_4.jpg");
    }

    &.-zegge {
        background-image: url("../../styleguide-images/image_3.jpg");
    }

    /* Set a specific height */
    min-height: 100vh;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2));
        background-size: cover;
        pointer-events: none;
    }
}

.jumbotron__arrow-down {
    position: absolute;
    bottom: 5.7rem;
    z-index: 1;
    display: block;
    margin-top: 3.5rem;
    width: 5.81rem;
    height: 5.8rem;
    opacity: 1;
    transition: opacity .5s;

    background-image: url("../icons/go-down.svg");
    background-size: 5.81rem 5.8rem;
    background-repeat: no-repeat;

    &.-fade-out {
        opacity: 0;
    }
}

.jumbotron__title {
    position: sticky;
    bottom: 15.2rem;
    padding-top: 5rem;

    h1 {
        font-family: var(--font-bold);
        font-size: 5rem;
        line-height: 1.04;
        color: var(--color-off-white);
        text-align: left;
        max-width: 54rem;
        margin-bottom: 3.5rem;
    }

    &.is-pinned {
        h1 {
            color: red;
        }
    }
}

.jumbotron__content {
    padding: 0 0 6rem;
    color: var(--color-off-white);
}

.jumbotron__left {
    margin-bottom: 5.5rem;
}

.jumbotron__video {
    display: block;
    position: relative;
}

.jumbotron__video__image {
    width: 100%;
    height: 60vw;
    object-fit: cover;
    border-radius: 21rem;
    overflow: hidden;
}

.jumbotron__video__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: var(--color-crimson-red);
    width: 5.81rem;
    height: 5.8rem;
    border-radius: 50%;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url("../icons/play.svg");
        background-size: 1rem 1.7rem;
        background-repeat: no-repeat;
        width: 1rem;
        height: 1.7rem;
    }

    &:hover, &:focus {
        background-color: var(--color-crimson-red-hover);
    }
}

@media (--screen-large) {
    .jumbotron {
        margin-bottom: 10rem;

        h1 {
            font-size: 10rem;
        }
    }

    .jumbotron__image {
        min-height: 100vh;
    }

    .jumbotron__title {
        bottom: 18.2rem;
        padding-top: 10.2rem;

        h1 {
            max-width: 50%;
            margin-bottom: 6.5rem;
        }
    }

    .jumbotron__content {
        padding: 0 0 10.2rem;
    }

    .jumbotron__content .container {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
    }

    .jumbotron__left {
        max-width: 50%;

        & > * {
            max-width: 54rem;
        }
    }

    .jumbotron__right {
        position: absolute;
        top: -20rem;
        right: 2rem;
        width: 40rem;
        margin-left: 4rem;
        opacity: 1;
        transition: opacity 1s;

        &.-fade-out {
            opacity: 0;
        }
    }

    .jumbotron__video__image {
        width: 40rem;
        height: 60.6rem;

        & .video {
            height: 100%;
            padding: 0;
        }
    }
}

@media only screen and (min-width: 1000px) {
    .jumbotron__title h1 {
        max-width: 54rem;
    }
}
