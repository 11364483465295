div[data-block-type="image-image"] {
    margin-bottom: 5rem;

    & + & {
        margin-top: -4rem;
    }
}

.image-image {
    display: flex;
    flex-direction: column;

    .image {
        margin-bottom: 1rem;

        img {
            @include image;
        }
    }
}

@media (--screen-medium-large) {
    .image-image {
        flex-direction: row;

        .image {
            margin: 0 1rem 0 0;
            width: calc(50% - .5rem);

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}
