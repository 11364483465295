.page-header {
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 0;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
    }

    &.-open {
        min-height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: var(--color-off-white);
        padding: 1rem 0 0;
        overflow-y: auto;

        .container {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 0;
            box-sizing: border-box;
            height: 100%;
        }

        .page-header__top {
            height: 20%;
        }
    }

    &.-fixed {
        background-color: var(--color-off-white);
    }
}

.page-header__top {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.page-header__logo {
    display: block;
    width: 16rem;
    height: 4rem;
    background-image: url("../icons/AZF-logo-BG.svg");
    background-size: 16rem 4rem;
    background-repeat: no-repeat;

    font-size: 0;

    .page-header.-off-white & {
        background-image: url("../icons/AZF-logo.svg");
    }

    .page-header.-open & {
        background-image: url("../icons/AZF-logo-BG.svg");
    }
}

.page-header__bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.page-header__nav {
    display: none;
    padding: 4rem 0;
    opacity: 0;
    transition: all .3s ease;

    .page-header.-open & {
        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: 1;
    }
}

.page-header__nav__link {
    display: block;
    color: var(--color-blue-green);
    font-size: 3.2rem;
    font-family: var(--font-bold);
    line-height: 1.8;
    text-decoration: none;

    &.button {
        margin-top: 1rem;
        padding: 1.2rem 2.8rem;
        color: var(--color-off-white);
        font-size: 1.8rem;
    }
}

@media (--screen-medium-large) {
    .page-header {
        padding: 2rem 0;

        .container {
            padding: 0 2rem;
        }
    }

    .page-header__logo {
        width: 25rem;
        height: 6.2rem;
        background-size: 25rem 6.2rem;
    }

    .page-header__nav__link {
        &.button {
            margin-top: 0;
        }
    }
}

@media (--screen-extra-large) {
    .page-header__top {
        width: auto;
    }

    .page-header__nav {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        padding: 0;
        opacity: 1;
    }

    .page-header__nav__link {
        display: inline-block;
        padding-bottom: .4rem;
        margin: .4rem 0 0 4.2rem;
        font-size: 1.6rem;
        line-height: 1.2;
        border-bottom: .2rem solid transparent;

        .page-header.-off-white & {
            color: var(--color-off-white);
        }

        &:hover, &:focus, &.-active {
            border-bottom: .2rem solid var(--color-crimson-red);
        }
    }

    .page-header__bottom {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
    }
}
