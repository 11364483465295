.breadcrumb {
    margin-bottom: 1.5rem;
}

.breadcrumb__link {
    position: relative;
    padding-left: 4rem;

    font-size: 1.4rem;
    line-height: 1.75;
    color: var(--color-blue-green);
    text-decoration: none;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transition: all .3s ease;
        background-repeat: no-repeat;
    }

    &::before {
        background-image: url("../icons/arrow-icon.svg");
        background-size: .66rem 1.2rem;
        width: .66rem;
        height: 1.2rem;
        transform: translateY(-50%) rotate(180deg);

        @supports (mask-repeat: no-repeat) {
            background: var(--color-blue-green);
            mask-image: url("../icons/arrow-icon.svg");
            mask-repeat: no-repeat;
            mask-size: .66rem 1.2rem;
        }
    }

    &::after {
        left: .2rem;
        background-color: var(--color-blue-green);
        background-size: 2.5rem .2rem;
        width: 2.5rem;
        height: .2rem;
        transform: translateY(-50%);
    }

    &:hover, &:focus {
        padding-left: 5.5rem;

        &::after {
            width: 3.5rem;
        }
    }

    .page-head.-with-image & {
        color: var(--color-off-white);

        &::before {
            @supports (mask-repeat: no-repeat) {
                background: var(--color-off-white);
            }
        }

        &::after {
            background-color: var(--color-off-white);
        }
    }
}

@media (--screen-medium-large) {
    .breadcrumb {
        margin-bottom: 2.2rem;
    }

    .breadcrumb__link {
        font-size: 1.6rem;
    }
}
