ul {
    margin: 1rem 0 3.2rem;
    padding: 0 0 0 1.7rem;
    list-style: none;
    line-height: 1.67;

    li {
        margin: 0 0 .4rem;
        position: relative;

        &::before {
            position: absolute;
            left: -1.7rem;
            top: calc(.83em - .25rem);
            content: '';
            width: .5rem;
            height: .5rem;
            background-color: var(--color-blue-green);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    li > & {
        margin: .8rem 0 0;
    }
}
