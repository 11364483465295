.form__message {
    color: var(--color-crimson-red);
    font-size: 1.3rem;
    display: block;
    text-align: left;
    margin-top: .6rem;
    box-sizing: border-box;
    order: 10;

    &::before {
        content: '';
        display: inline-block;
        vertical-align: text-bottom;
        margin-right: .6rem;
        background-image: url("includes/error.svg");
        background-size: 2rem 1.9rem;
        width: 2rem;
        height: 1.9rem;
    }

    .form__entry.-checkbox & {
        margin-bottom: 2rem;
    }
}
