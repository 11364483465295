.form__checkbox {
    border: 0;
    display: none;

    + .form__label {
        position: relative;
        padding-left: 2.2rem;
        margin-bottom: .8rem;
        font-family: var(--font-regular);

        &::before,
        &::after {
            content: '';

            display: block;
            width: 1.6rem;
            height: 1.6rem;

            position: absolute;
            top: 0;
            left: 0;
        }

        &::before {
            transition: box-shadow .2s ease,
            background-color .2s ease;
            box-shadow: inset 0 0 0 .1rem var(--color-borders);
            border-radius: .2rem;
        }

        &::after {
            transition: opacity .3s ease,
            transform .2s ease;

            color: var(--color-crimson-red);
            z-index: 1;
            opacity: 0;
            transform: scale(1.1);

            background-image: url("includes/check.svg");
            background-size: 1rem .8rem;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    &:checked + .form__label::before {
        box-shadow: inset 0 0 0 .1rem var(--color-crimson-red);
        background-color: var(--color-crimson-red);
    }

    &:checked + .form__label::after {
        opacity: 1;
        transform: scale(1);
    }

    &:disabled + .form__label::before, &:disabled + .form__label::after {
        opacity: .4;
    }
}
