.paragraph-paragraph {
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
        max-width: 47rem;
        margin-right: 2rem;
    }
}

@media (--screen-medium-large) {
    .paragraph-paragraph {
        flex-direction: row;
        justify-content: space-between;
    }
}
