.page-footer {
    flex-shrink: 0;
}

.page-footer__partners {
    border-top: .1rem solid var(--color-borders);
    border-bottom: .1rem solid var(--color-borders);
    padding: .9rem 0 .3rem;
}

.page-footer__bottom {
    padding: 1.9rem 0 1.6rem;

    font-family: var(--font-regular);
    font-size: 1.4rem;
    line-height: 1.5;
    color: var(--color-blue-green);
}

.page-footer__bottom__copy {
    text-align: center;
    margin-bottom: 1rem;
    white-space: nowrap;

    img {
        display: block;
        width: 3rem;
        height: 3rem;
        margin: 0 auto 1.4rem;
    }
}

.page-footer__bottom__legal {
    margin-bottom: 1rem;

    a {
        display: block;
        text-align: center;
        text-decoration: none;
        white-space: nowrap;
        color: var(--color-blue-green);

        &:hover, &:focus {
            text-decoration: underline;
        }
    }
}

@media (--screen-small-large) {
    .page-footer__partners {
        padding: .9rem 0 1rem;
    }

    .page-footer__bottom {
        padding: 2.1rem 0 1.1rem;

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }
    }

    .page-footer__bottom__copy {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
            display: inline-block;
            margin: 0 1.8rem 0 0;
        }
    }

    .page-footer__bottom__legal {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        a {
            display: inline-block;

            &:not(:first-of-type) {
                &::before {
                    content: ' - ';
                    display: inline-block;
                    margin: 0 .5rem;
                }
            }
        }
    }
}
