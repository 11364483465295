.intro {
    color: var(--color-blue-green);
    font-size: 1.8rem;
    margin-bottom: 4rem;
}

@media (--screen-medium-large) {
    .intro {
        font-size: 2.2rem;
        margin-bottom: 7.4rem;
    }
}
