main {
    flex: 1 0 auto;
}

@media (--screen-medium-large) {
    main {
        margin-top: 5rem;

        body[type="with-image"] & {
            margin-top: 0;
        }
    }
}
