.row {
    margin: 0;
    padding: 0 2rem;

    &.-size-full {
        padding: 0;
    }
}

.row__sizer {
    width: 114rem;
    max-width: 100%;
    margin: 0 auto;

    .row.-size-full & {
        width: 100%;
    }
}

.row__inner {
    width: 62rem;
    max-width: 100%;
    margin: 0 auto;

    .pagecontent-row-container.-align-left &, .row.-align-left & {
        margin-left: 0;
    }

    .row.-size-wide &,
    .row[data-block-type="heading-1"].-left & {

        width: 106rem;
    }

    .row.-size-full & {
        width: 100%;
    }

    .row[data-block-type="intro"] & {
        width: 84rem;
    }
}
