@mixin no-list {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        margin: 0;

        &::before {
            display: none;
        }
    }
}

@mixin image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
