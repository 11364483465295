h1,
.title-1,
h2,
.title-2,
h3,
.title-3,
h4,
.title-4,
h5,
.title-5,
h6,
.title-6 {
    margin: 0 0 2.1rem;
    line-height: 1.2;
    color: var(--color-blue-green);
    font-family: var(--font-bold);
}

h1, .title-1 {
    margin-bottom: 2.9rem;
    font-size: 4.2rem;
    text-align: center;
}

h2, .title-2 {
    font-size: 3.2rem;
    margin-bottom: 1.7rem;
}

h3, .title-3 {
    font-size: 2.4rem;
    margin-bottom: 1.7rem;
}

h4, .title-4 {
    font-size: 1.8rem;
}

h5, .title-5 {
    font-size: 1.5rem;
}

@media (--screen-medium-large) {
    h1, .title-1 {
        font-size: 6.4rem;
        line-height: 1;
    }

    h2, .title-2 {
        font-size: 4.8rem;
    }

    h3, .title-3 {
        margin-bottom: 2.5rem;
        font-size: 3.6rem;
    }

    h4, .title-4 {
        margin-bottom: 2.5rem;
        font-size: 2.4rem;
    }

    h5, .title-5 {
        margin-bottom: 3.1rem;
        font-size: 1.8rem;
    }
}
