.form__label {
    font-family: var(--font-bold);
    font-size: 1.6rem;
    line-height: 1.2;
    margin-bottom: .8rem;

    &.-select {
        pointer-events: none;
    }

    .form__field + & {
        display: block;
    }
}

[readonly] + label {
    cursor: default;
}
