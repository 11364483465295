.form__entry {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    &.-checkbox,
    &.-radio {
        flex-direction: row;
        margin-top: 4rem;

        & + .form__entry.-checkbox,
        & + .form__entry.-radio {
            margin-top: 2rem;
        }
    }

    &.-upload {
        margin-bottom: 3rem;
    }
}
