@import '~glightbox/dist/css/glightbox.min.css';

.gallery-wrapper {
    max-width: 62rem;
    margin: 2.8rem auto 3.8rem;
}

.gallery {
    @include no-list;

    display: block;
    column-count: 2;
    column-gap: 1rem;

    h2 + & {
        margin-top: 2.5rem;
    }
}

li.gallery__item {
    display: block;
    margin-bottom: 1rem;

    img {
        @include image;
    }
}

.gallery__item__link {
    display: block;
    position: relative;

    text-decoration: none;

    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background: url("../icons/view.svg") no-repeat center;
        background-size: 2.4rem 2.4rem;
        width: 2.4rem;
        height: 2.4rem;

        opacity: 0;
        transition: opacity .3s ease-in-out;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;

        background: var(--color-gallery-yellow);
        width: 100%;
        height: 100%;

        opacity: 0;
        transition: opacity .3s ease-in-out;
    }

    &:hover,
    &:focus {
        &::before,
        &::after {
            opacity: 1;
        }
    }
}

@media (--screen-medium-large) {
    .gallery-wrapper {
        margin: 5.6rem auto 7.5rem;
    }

    .gallery {
        column-count: 3;

        h2 + & {
            margin-top: 4.2rem;
        }
    }
}
