.form__radio {
    border: 0;
    display: none;

    + .form__label {
        position: relative;
        padding-left: 2.2rem;
        margin-bottom: .8rem;
        font-family: var(--font-regular);

        &::before,
        &::after {
            content: '';

            display: block;
            width: 1.6rem;
            height: 1.6rem;

            position: absolute;
            top: 0;
            left: 0;

            border-radius: 50%;
        }

        &::before {
            transition: box-shadow .2s ease,
            background-color .2s ease;
            box-shadow: inset 0 0 0 .1rem var(--color-borders);
        }

        &::after {
            transition: opacity .3s ease,
            transform .2s ease;

            z-index: 1;
            opacity: 0;
            transform: scale(1);

            width: .8rem;
            height: .8rem;

            top: .4rem;
            left: .4rem;

            background: var(--color-crimson-red);
        }
    }

    &:checked + .form__label::before {
        box-shadow: inset 0 0 0 .1rem var(--color-crimson-red);
    }

    &:checked + .form__label::after {
        opacity: 1;
        transform: scale(1);
    }

    &:disabled + .form__label::before, &:disabled + .form__label::after {
        opacity: .4;
    }
}
